import axios, { AxiosInstance } from 'axios';

const API_URL = process.env.REACT_APP_TEMPLATE_API_URL;

export const api: AxiosInstance = axios.create({
   baseURL: API_URL,
});

export const connectPageService = async () => {
   try {
      const response = await api.post(`/`);
      if (response.status === 200) {
         return true;
      } else {
         return false;
      }
   } catch (err) {
      console.log(err);
   }
};

export const setInfoService = async (info: any) => {
   try {
      const response = await api.post(`/info`, { ...info });
      if (response.status === 200) {
         return true;
      } else {
         return false;
      }
   } catch (err) {
      console.log(err);
   }
};
