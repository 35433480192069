export const enum ValidateKeyEnum {
   NAME,
   PHONE_NUM,
   IDENTITY_NUM,
}

export interface IValidate {
   key: ValidateKeyEnum;
   regexp: RegExp;
   replaceRegexp?: RegExp;
}

export const ValidateData: IValidate[] = [
   {
      key: ValidateKeyEnum.NAME,
      // 한글, 영어 최소 1 ~ 15자리까지 입력 가능 (유니코드)
      regexp: /^[\u3131-\u318E\uAC00-\uD7A3a-zA-Z]{1,15}$/,
   },
   {
      key: ValidateKeyEnum.PHONE_NUM,
      // 숫자만 입력 가능하며, 최대 13자리까지 입력 가능
      regexp: /^\d{1,13}$/,
   },
   {
      key: ValidateKeyEnum.IDENTITY_NUM,
      // 숫자가 첫번째 자리에 들어와야 하며 '-'(하이픈) 입력 가능
      regexp: /^\d{1,6}-?\d{0,7}$/,
      // 7번째 자리에 숫자를 입력할 경우
      replaceRegexp: /(\d{6})(?=\d)/,
   },
];
