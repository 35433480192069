import { useEffect, useRef, useState } from 'react';
import {
   Field,
   Input,
   makeStyles,
   Image,
   typographyStyles,
   Text,
   mergeClasses,
   Button,
   Checkbox,
   Label,
   InputOnChangeData,
   Toaster,
   useId,
   useToastController,
   Toast,
   ToastTitle,
   Spinner,
   Dialog,
   DialogSurface,
   DialogContent,
   DialogBody,
   DialogActions,
} from '@fluentui/react-components';
import { DatePicker } from '@fluentui/react-datepicker-compat';

import dayjs from 'dayjs';

import { ValidateData, ValidateKeyEnum } from './utils/validate.utils';
import { connectPageService, setInfoService } from './service/service';
import './App.css';

const rootStyles = makeStyles({
   root: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      // height: '100%',
      // margin: '0 auto',
   },
   contentContainer: {
      display: 'flex',
      maxWidth: '80%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '40px 15px',
      flex: 1,
      gap: '30px',
   },

   titleContainer: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '30px',
   },
   titleText: {
      ...typographyStyles.title1,
      // '@media screen and (max-width:991px) { font-size: 10px }' : {}
      '@media screen and (max-width:991px) and (min-width: 768px)': {
         marginTop: '0px',
         marginBottom: '0px',
         textAlign: 'center',
         fontSize: '23px',
         letterSpacing: '-1px',
      },
      '@media screen and (max-width: 767px)': {
         marginTop: '10px',
         marginBottom: '0px',
         textAlign: 'center',
         fontSize: '23px',
         letterSpacing: '-1.7px',
         lineHeight: 1.3,
      },
   },
   agreementContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: '30px',
      marginTop: '',
      '@media screen and (max-width: 767px)': {
         display: 'flex',
         margin: '20px 0px 10px 0px',
      },
   },
   agreementSubContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
   },
   subTitle: {
      ...typographyStyles.subtitle2,
   },
   agreementText: {
      ...typographyStyles.body2,
   },
   inputInfoContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
   },
   footer: {
      textAlign: 'center',
   },
   row: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      gap: '10px',
   },
   input: {
      width: '100%',
      minWidth: 0,
   },
   col: {
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
   },
   checkBoxError: {
      '& div': {
         'border-color': 'red',
      },
   },
});

function App() {
   const styles = rootStyles();
   const toasterId = useId('rootToaster');
   const { dispatchToast } = useToastController(toasterId);

   const nameRef = useRef<HTMLInputElement>(null);
   const managerRef = useRef<HTMLInputElement>(null);
   const phoneNumRef = useRef<HTMLInputElement>(null);
   const identityNumRef = useRef<HTMLInputElement>(null);
   const submitDateRef = useRef<HTMLInputElement>(null);

   const [initPage, setInitPage] = useState<boolean>(false);
   const [name, setName] = useState<string>('');
   const [errorName, setErrorName] = useState<boolean>(false);
   const [manager, setManager] = useState<string>('');
   const [errorManager, setErrorManger] = useState<boolean>(false);
   const [phoneNum, setPhoneNum] = useState<string>('');
   const [errorPhoneNum, setErrorPhoneNum] = useState<boolean>(false);
   const [identityNum, setIdentityNum] = useState<string>('');
   const [errorIdentityNum, setErrorIdentityNum] = useState<boolean>(false);
   const [submitDate, setSubmitDate] = useState<Date>(new Date());
   const [errorSubmitDate, setErrorSubmitDate] = useState<boolean>(false);
   const [isAgree, setIsAgree] = useState<boolean>(false);
   const [errorAgree, setErrorIsAgree] = useState<boolean>(false);
   const [isComplete, setIsComplete] = useState<boolean>(false);
   const [isResultLoading, setIsResultLoading] = useState<boolean>(false);

   useEffect(() => {
      connectPageService().then((response) => {
         if (response) setInitPage(response);
      });
   }, []);

   const Alert = (text: string) => {
      return (
         <Toast>
            <ToastTitle>{text}</ToastTitle>
         </Toast>
      );
   };

   const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
      const regex: RegExp = ValidateData.find((element) => element.key === ValidateKeyEnum.NAME)?.regexp!;

      if (regex.test(data.value) || data.value === '') {
         setName(data.value);
         setErrorName(false);
      }
   };

   /**
    * ! 고민 (동명이인이 있을 확률이 매우 큼)
    * @param event
    * @param data
    */
   const handleChangeManager = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
      const regex: RegExp = ValidateData.find((element) => element.key === ValidateKeyEnum.NAME)?.regexp!;
      if (regex.test(data.value) || data.value === '') {
         setManager(data.value);
         setErrorManger(false);
      }
   };

   const handleChangePhoneNum = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
      const regex: RegExp = ValidateData.find((element) => element.key === ValidateKeyEnum.PHONE_NUM)?.regexp!;
      if (regex.test(data.value) || data.value === '') {
         setPhoneNum(data.value);
         setErrorPhoneNum(false);
      }
   };

   const handleChangeIdentityNum = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
      const regex: RegExp = ValidateData.find((element) => element.key === ValidateKeyEnum.IDENTITY_NUM)?.regexp!;
      const replaceRegex: RegExp = ValidateData.find((element) => element.key === ValidateKeyEnum.IDENTITY_NUM)?.replaceRegexp!;

      if (data.value.charAt(data.value.length - 1) === '-') {
         data.value = data.value.slice(0, -1);
      }

      if (data.value.length > 6 && !data.value.includes('-')) {
         data.value = data.value.replace(replaceRegex, '$1-');
      }

      if (regex.test(data.value) || data.value === '' || data.value.length < 15) {
         setIdentityNum(data.value);
         setErrorIdentityNum(false);
      }
   };

   const handleSelectSubmitDate = (date?: Date | null) => {
      setSubmitDate(date!);
   };

   const handleClickSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
      let text: string = '';
      if (!name) {
         text = '이름을 입력해 주세요';
         nameRef.current?.focus();
         setErrorName(true);
      } else if (!phoneNum || phoneNum.length < 10) {
         text = '연락처를 입력해 주세요';
         phoneNumRef.current?.focus();
         setErrorPhoneNum(true);
      } else if (!submitDate) {
         text = '일자를 선택해 주세요';
         submitDateRef.current?.focus();
         setErrorSubmitDate(true);
      } else if (!identityNum || identityNum.length !== 14) {
         text = '주민등록번호를 입력해 주세요';
         identityNumRef.current?.focus();
         setErrorIdentityNum(true);
      } else if (!manager) {
         text = '담당자를 입력해주세요';
         managerRef.current?.focus();
         setErrorManger(true);
      } else if (!isAgree) {
         text = '개인정보취급에 대한 동의를 해주세요';
         setErrorIsAgree(true);
      } else {
         text = '';
         initErrorState();
      }

      if (text !== '') {
         dispatchToast(Alert(text), { intent: 'error' });
      } else {
         const info = {
            name: name,
            phoneNum: phoneNum,
            identityNum: identityNum,
            submitDate: dayjs(submitDate).format('YYYY-MM-DD'),
            manager: manager,
            isAgree: isAgree,
         };
         setIsResultLoading(true);
         const result = await setInfoService(info);
         if (result) {
            setIsComplete(true);
            setIsResultLoading(false);
         }
      }
   };

   const initErrorState = () => {
      setErrorPhoneNum(false);
      setErrorIdentityNum(false);
      setErrorManger(false);
      setErrorName(false);
      setErrorIsAgree(false);
      setErrorSubmitDate(false);
   };

   return (
      <div className="App">
         {initPage ? (
            <>
               <div className={styles.root}>
                  <Toaster toasterId={toasterId} limit={1} position="top" timeout={1500} />
                  <div className={styles.contentContainer}>
                     <div className={styles.titleContainer}>
                        <Image src={'../existing.png'} width={'100%'} />
                        <Text className={styles.titleText}>숨은 보험금 찾기 대행을 위한 필수 개인(신용)정보 처리 동의서</Text>
                     </div>
                     <div className={styles.agreementContainer}>
                        <div className={styles.agreementSubContainer}>
                           <Text className={styles.subTitle}>◆ 소비자 권익보호에 관한 사항</Text>
                           <Text className={styles.agreementText}>
                              본 동의를 거부하시는 경우에는 숨은 보험금 찾기 관련 서비스가 일부 제한될 수 있고, 본 동의서에 의한 개인
                              (신용)정보 조회는 귀하의 신용등급에 영향을 주지 않습니다. 동의 이후 당사 고객센터를 통해 동의를 철회하실 수
                              있습니다.
                           </Text>
                        </div>
                        <div className={styles.agreementSubContainer}>
                           <Text className={styles.subTitle}>◆ 개인정보의 수집 · 이용 목적</Text>
                           <Text className={mergeClasses(styles.agreementText, styles.col)}>
                              ◎ 숨은 보험금 찾기 대행 서비스, 보상 관련 안내
                              <div>◎ 보장 분석(기존 보험 조회 및 분석)관련 업무</div>
                           </Text>
                        </div>
                        <div className={styles.agreementSubContainer}>
                           <Text className={styles.subTitle}>◆ 수집 · 이용할 개인정보의 내용</Text>
                           <Text className={styles.agreementText}>◎ 개인식별정보 (성명, 주민등록번호, 외국인등록번호, 전화번호)</Text>
                        </div>
                        <div className={styles.agreementSubContainer}>
                           <Text className={styles.subTitle}>◆ 개인정보의 보유 · 이용기간</Text>
                           <Text className={styles.agreementText}>◎ 수집 · 이용 동의일로부터 6개월까지 (6개월 후 개인정보 자동폐기)</Text>
                        </div>
                        <div className={styles.agreementSubContainer}>
                           <Text className={styles.subTitle}>◆ 개인정보의 수집 이용자 : (주)글로벌금융판매</Text>
                        </div>
                        <div className={styles.agreementSubContainer}>
                           <Text className={styles.subTitle}>◆ 개인정보 제3자 제공동의</Text>
                           <Text className={styles.agreementText}>
                              ◎ 개인정보를 제공받는자 (주)글로벌금융판매와 위 개인정보 수집 이용 목적에 따라 위탁 업무에 대한 계약을 체결한
                              자
                           </Text>
                        </div>
                     </div>
                     <div className={styles.inputInfoContainer}>
                        <div className={styles.row}>
                           <Field className={styles.input} label={'성명'} validationState={errorName ? 'error' : undefined}>
                              <Input ref={nameRef} className={styles.input} value={name} size="large" onChange={handleChangeName} />
                           </Field>
                           <Field className={styles.input} label={'연락처'} validationState={errorPhoneNum ? 'error' : undefined}>
                              <Input
                                 placeholder="숫자만 입력해 주세요"
                                 ref={phoneNumRef}
                                 className={styles.input}
                                 size="large"
                                 value={phoneNum}
                                 onChange={handleChangePhoneNum}
                              />
                           </Field>
                        </div>
                        <div className={styles.row}>
                           <Field className={styles.input} label={'주민등록번호'} validationState={errorIdentityNum ? 'error' : undefined}>
                              <Input
                                 ref={identityNumRef}
                                 placeholder="숫자만 입력해 주세요"
                                 className={styles.input}
                                 value={identityNum}
                                 size="large"
                                 onChange={handleChangeIdentityNum}
                              />
                           </Field>
                           <Field className={styles.input} label={'날짜'} validationState={errorSubmitDate ? 'error' : undefined}>
                              <DatePicker
                                 ref={submitDateRef}
                                 className={styles.input}
                                 size="large"
                                 value={submitDate}
                                 formatDate={(date) => dayjs(date).format('YYYY-MM-DD')}
                                 onSelectDate={handleSelectSubmitDate}
                                 showMonthPickerAsOverlay={true}
                              />
                           </Field>
                        </div>
                        <div className={styles.row} style={{ width: '50%', alignItems: 'start' }}>
                           <Field className={styles.input} label={'담당자'} validationState={errorManager ? 'error' : undefined}>
                              <Input
                                 ref={managerRef}
                                 className={styles.input}
                                 value={manager}
                                 size="large"
                                 onChange={handleChangeManager}
                              />
                           </Field>
                        </div>
                        <div className={styles.row} style={{ marginTop: '20px', justifyContent: 'end' }}>
                           <Label>※ 필수 체크 항목작성에 대한 동의</Label>
                        </div>
                        <div className={styles.row} style={{ justifyContent: 'center' }}>
                           <Checkbox
                              className={errorAgree ? styles.checkBoxError : undefined}
                              checked={isAgree}
                              label={'개인정보취급방침에 대한 동의'}
                              onChange={(ev, data) => setIsAgree(data.checked as boolean)}
                           />
                        </div>
                        <div className={styles.row} style={{ marginTop: '20px' }}>
                           <Button
                              appearance="primary"
                              disabled={isResultLoading}
                              style={{ width: '100%' }}
                              size="large"
                              onClick={handleClickSubmit}
                           >
                              {isResultLoading ? <Spinner label={"제출중"}/> : '제출하기'}
                           </Button>
                        </div>
                        <div className={styles.row} style={{ marginTop: 20, justifyContent: 'start' }}>
                           <Text className={styles.subTitle}> ※ 보험금 지급 관련 안내사항</Text>
                        </div>
                        <div className={styles.row} style={{ justifyContent: 'start' }}>
                           <Text className={styles.agreementText} style={{ fontSize: '0.8rem' }}>
                              숨은 보험금 찾기 서비스는 고객님의 기 가입된 보험의 보장 내용을 토대로 안내를 드릴 뿐 실제 보험금 지급
                              유무와는 관련이 없음을 안내드립니다.
                           </Text>
                        </div>
                     </div>
                     <div className={styles.footer}>
                        <Image src="/global_logo.png" width={'50%'} />
                     </div>
                  </div>
               </div>
            </>
         ) : (
            <div style={{ display: 'flex', height: '100vh', justifyContent: 'center' }}>
               <Spinner label={'wait loading...'} labelPosition="below" />
            </div>
         )}

         <Dialog open={isComplete} modalType="modal">
            <DialogSurface>
               <DialogBody>
                  <DialogContent>제출되었습니다.</DialogContent>
                  <DialogActions>
                     <Button appearance="primary" onClick={() => window.location.reload()}>
                        확인
                     </Button>
                  </DialogActions>
               </DialogBody>
            </DialogSurface>
         </Dialog>
      </div>
   );
}

export default App;
